import React, { useState, useEffect } from 'react';
import './Users.css';
import { Checkbox, FormControlLabel, FormGroup, Grid as GridList, Grid as GridListTile } from '@mui/material';
import { GrpcClients } from './shared/services/grpc.services';
import { User } from './gen/envoy_sample_pb';
import { Empty } from 'google-protobuf/google/protobuf/empty_pb';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {

  const [users, setUsers] = useState<User[]|undefined>();

  useEffect(() => {

    GrpcClients.envoy().getUsers(new Empty(), {}, (err, res) => {
      if (err) {
        console.log(err);
        return
      }
      setUsers(res.getUsersList());
    });
  }, [])

  var isAliceReport = (user:User) => {
    var alice = users?.find(u => u.getName() === 'Alice');
    // console.log(`xxxx: ${alice?.getReportsList().find(u => user.getName() === u.getName())}`);
    return alice?.getReportsList().find(u => user.getName() === u.getName()) !== undefined
  }

  var handleUserChange = (user:User) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsers((users) => {

      // console.log(`event: ${event.target.checked}`)
      var alice = users?.find(u => u.getName() === 'Alice');
      if (event.target.checked) {
        alice?.addReports(user);
      } else {
        alice?.setReportsList(alice.getReportsList().filter(u => u.getName() !== user.getName()))
      }
      // maybe change to set users in here when this is uncommented
      GrpcClients.envoy().updateUser(alice!, {},() =>{})
      return users?.slice(0);
    })
  }

  return (
    <div className="Body">
      <GridList container columns={1} className="Name-content">
        {
          users && users.filter(u => u.getName() !== "Alice").map((user, i) => (
            <GridListTile item key={i} columns={1} className="Name-text">
              <FormGroup>
                <FormControlLabel control={<Checkbox onChange={handleUserChange(user)} checked={isAliceReport(user)}/>} label={user.getName()}/>
              </FormGroup>
            </GridListTile>
          ))
        }
      </GridList>
      <span>Alice's Reports</span>
    </div>
  );
}

