import { EnvoySampleServiceClient } from '../../gen/envoy_sample_grpc_web_pb';

const localDev = 'http://localhost:9095';
// const localDev = 'http://localhost:3001';

const normalUrl = `https://${window.location.hostname}`;

const fullUrl = window.location.host.includes('localhost')?localDev:normalUrl;
const envoy:EnvoySampleServiceClient = new EnvoySampleServiceClient(fullUrl, null, null);

export class GrpcClients {
  static envoy = ():EnvoySampleServiceClient => {
    return envoy;
  }
}
