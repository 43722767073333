/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import './Results.css';
import { FormControlLabel, FormGroup, Grid, Switch, Tab} from '@mui/material';
import { GrpcClients } from './shared/services/grpc.services';
import { TestResult } from './gen/envoy_sample_pb';
import { Empty } from 'google-protobuf/google/protobuf/empty_pb';
import StatusIcon from '@mui/icons-material/FiberManualRecordTwoTone';
import TabContext from '@mui/lab/TabContext';
import { TabList, TabPanel } from '@mui/lab';

const getIconColor = (active: boolean) => {
  switch (active) {
    case true:
      return "green";
    case false:
      return "red";
  }
}

var testResults = () => {

  const [tab, setTab] = useState("1")
  const [test, setTest] = useState(false)
  const [results, setTestResults] = useState<TestResult[]>([]);
  const ref = React.createRef<HTMLSpanElement>();

  useEffect(() => {
    if (test) {
      const stream = GrpcClients.envoy().runTests(new Empty(), {});
      stream.on('error', (err) => {
        // TODO: likely want to restart the stream (with some sort of incremental backoff)
        console.log(`error called: ${err.message}`);
      });
      stream.on('data', (res) => {
          setTestResults((results) => {
            const ms = results.slice();
            ms.unshift(res!);
            return ms;
          });
        } 
      );
      stream.on('error', (res) => {
        setTest(false);
        setTimeout(() => {
          setTest(true);
      }, 1000);
      } 
    );

      return () => {
        console.log('going out');
        stream.cancel();
      }
    }
  }, [test])

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results])

  return (
    <div className="Body">
      <FormGroup>
        <FormControlLabel control={<Switch checked={test} onChange={(e) => setTest(!test)}/>} label="Run Test Calls"/>
      </FormGroup>

      <TabContext value={tab}>
        <TabPanel value="1" className="Message-content">
          <RollingResults results={results}/>
        </TabPanel>
        <TabPanel value="2" className="Message-content">
          <LastResult results={results}/>
        </TabPanel>
        <TabList  onChange={(event: React.SyntheticEvent, newValue: string)=>setTab(newValue)}>
            <Tab label="Rolling" value="1" />
            <Tab label="Last Result" value="2" />
        </TabList>
      </TabContext>
    </div>
  );
}

export default testResults

interface TabProps {
  results: TestResult[]
}

var RollingResults = ({results}:TabProps) => {

  var i = 0;
  return (
    <Grid container className="Message-content">
        {
          results.map((result) => (
            <>
            <Grid item key={++i} xs={8} className="Message-text">
              <span className="Icon-top"><StatusIcon fontSize='small' className={getIconColor(result.getAllowed())}/></span>
              <span className="Message-name">{result.getUrl()}</span>
            </Grid>
            <Grid item key={++i} xs={4} className="Message-text">
              <span className="Message-text">{result.getTime()?.toDate().toLocaleTimeString()}</span>
            </Grid>
            </>
            ))
        }
      </Grid>
  )
}

var LastResult = ({results}:TabProps) => {

  var i = 0;
  const [summary, setSummary] = useState<TestResult[]>([])

  useEffect(() => {
    if (results.length > 0) {
      var result = results[0];

      setSummary((sum)=> {
        var newSum = sum.filter(r => r.getUrl() !== result.getUrl());
        newSum.push(result)
        return newSum.sort((r1,r2)=>r1.getUrl().localeCompare(r2.getUrl()));
      })
  
    }

  }, [results])

  return (
    <Grid container className="Message-content">
        {
          
          summary && summary.map((result) => (
            <>
            <Grid item key={++i} xs={8} className="Message-text">
              <span className="Icon-top"><StatusIcon fontSize='small' className={getIconColor(result.getAllowed())}/></span>
              <span className="Message-name">{result.getUrl()}</span>
            </Grid>
            <Grid item key={++i} xs={4} className="Message-text">
              <span className="Message-text">{result.getTime()?.toDate().toLocaleTimeString()}</span>
            </Grid>
            </>
            ))
        }
      </Grid>
  )
}