import React from 'react';
import logo from './styra.png';
import Paper from '@mui/material/Paper'
import './App.css';
import Results from './Results';
import Users from './Users';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <div className="App-body">
        <Paper className="User-body">
          <Users/>
        </Paper>
        <Paper className="Message-body">
          <Results/> 
        </Paper>
      </div>
    </div>
  )
}

export default App;
